import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { ModelList } from '@/model/modelList'

export const getItems = (params: any) => {
  return https().request<RootObject<ModelList<any>>>('batch/items', Method.POST, params, ContentType.json)
}

export const download = (params: any) => {
  return https().request<RootObject<any>>('excel/export', Method.POST, params, ContentType.json)
}
