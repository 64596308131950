<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keywords"
        :placeholder="t('table.keywords')"
        style="width: 210px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        {{ t("table.search") }}
      </el-button>
    </div>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      fit
      highlight-current-row
      empty-text="暂无数据"
      style="width:100%"
    >
      <el-table-column
        :label="t('table.id')"
        prop="id"
        align="center"
        width="80px"
      >
        <template #default="{row}">
          <span>{{ (listQuery.index-1)*listQuery.size+list.indexOf(row) + 1 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="t('table.shipNameAndvoyageNumber')"
        width="300px"
      >
        <template #default="{row}">
          <span>{{ row.shipName }}/{{ row.voyageNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.checkedByTotal')"
        align="center"
      >
        <template #default="{row}">
          <span>{{ row.checkedTotal }}/{{ row.total }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="120px"
      >
        <template
          #default="{row}"
        >
          <el-button
            v-show="row.checkedTotal - row.qualifiedTotal > 0"
            v-waves
            :loading="list.indexOf(row) === downloadLoading"
            class="filter-item"
            type="primary"
            icon="el-icon-download"
            @click="handleDownload(row)"
          >
            {{ t("table.download") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-show="total > 0"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
  <a
    ref="adownloading"
  />
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import {
  download,
  getItems
} from '@/apis/bitch'
import { VinModel } from '@/model/vinModel'
import { useI18n } from 'vue-i18n'
import networkConfig from '@/config/default/net.config'
import { ElLink } from 'element-plus'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const adownloading = ref(ElLink)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VinModel>(),
      total: 0,
      listLoading: true,
      excelURL: '',
      listQuery: {
        index: 1,
        size: 10,
        keywords: ''
      },
      downLoadQuery: {
        shipName: undefined,
        voyageNumber: undefined
      },
      pageviewsData: [],
      downloadLoading: -1,
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.index = index
        }
        if (size) {
          dataMap.listQuery.size = size
        }
        dataMap.listLoading = true
        const data = await getItems(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.index = 1
        dataMap.getList()
      },
      async handleDownload(row: any) {
        dataMap.downloadLoading = dataMap.list.indexOf(row)
        dataMap.downLoadQuery.shipName = row.shipName
        dataMap.downLoadQuery.voyageNumber = row.voyageNumber
        const data = await download(dataMap.downLoadQuery)
        dataMap.excelURL = networkConfig.host?.replace('/api', '') + data?.data
        adownloading.value.href = dataMap.excelURL
        setTimeout(() => {
          dataMap.downloadLoading = -1
          adownloading.value.click()
        }, 3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList(null, 10)
    })
    return { t, adownloading, ...toRefs(dataMap) }
  }
})
</script>
