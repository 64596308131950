
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import {
  download,
  getItems
} from '@/apis/bitch'
import { VinModel } from '@/model/vinModel'
import { useI18n } from 'vue-i18n'
import networkConfig from '@/config/default/net.config'
import { ElLink } from 'element-plus'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const adownloading = ref(ElLink)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VinModel>(),
      total: 0,
      listLoading: true,
      excelURL: '',
      listQuery: {
        index: 1,
        size: 10,
        keywords: ''
      },
      downLoadQuery: {
        shipName: undefined,
        voyageNumber: undefined
      },
      pageviewsData: [],
      downloadLoading: -1,
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.index = index
        }
        if (size) {
          dataMap.listQuery.size = size
        }
        dataMap.listLoading = true
        const data = await getItems(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.index = 1
        dataMap.getList()
      },
      async handleDownload(row: any) {
        dataMap.downloadLoading = dataMap.list.indexOf(row)
        dataMap.downLoadQuery.shipName = row.shipName
        dataMap.downLoadQuery.voyageNumber = row.voyageNumber
        const data = await download(dataMap.downLoadQuery)
        dataMap.excelURL = networkConfig.host?.replace('/api', '') + data?.data
        adownloading.value.href = dataMap.excelURL
        setTimeout(() => {
          dataMap.downloadLoading = -1
          adownloading.value.click()
        }, 3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList(null, 10)
    })
    return { t, adownloading, ...toRefs(dataMap) }
  }
})
